import initialData from "../data/initial.json";

import {
  FullProduct,
  ListProduct,
  Category,
  InitialData,
} from "../../googleScripts/sheet/types";
import { createSignal } from "solid-js";
import { createCachedJsonFetcher } from "../lib/cached-json-fetcher";

const urlFullProduct = (category: string, productSlug: string) =>
  `/data/products/${category}/${productSlug}.json`;

const urlListProduct = (category: string) =>
  `/data/productList_${category}.json`;

export const shopData: InitialData = initialData;

export const [categories /*, setCategories*/] = createSignal<Category[]>(
  initialData.categories
);

export const [infoMessage /*, setInfoMessage*/] = createSignal<string>(
  initialData.infoMessage || ""
);

export const [daysOpen /*, setDaysOpen*/] = createSignal<string[]>(
  initialData.daysOpen || []
);

export const [daysClosed /*, setDaysClosed*/] = createSignal<string[]>(
  initialData.daysClosed || []
);

export const [isNewBuildStartup, setIsNewBuildStartup] = createSignal(false);

async function init() {
  const currentDate = localStorage.getItem("buildDate");
  setIsNewBuildStartup(initialData.build_date !== currentDate);
  localStorage.setItem("buildDate", initialData.build_date);
  isNewBuildStartup() && console.info("isNewBuildStartup");
}
/**
 * PRODUCT LISTS
 */

const productListFetcher = createCachedJsonFetcher();

export const getProductList = async ({
  categoryName,
  refetch = false,
}: {
  categoryName: string;
  refetch?: boolean;
}): Promise<ListProduct[]> => {
  return productListFetcher.load({
    url: urlListProduct(categoryName),
    refetch: refetch,
  }) as Promise<ListProduct[]>;
};

/**
 * PRODUCTS
 */

const fullProductFetcher = createCachedJsonFetcher();

export const getFullProduct = async ({
  categoryName,
  productSlug,
  refetch = true,
}: {
  categoryName: string;
  productSlug: string;
  refetch?: boolean;
}): Promise<FullProduct> => {
  return fullProductFetcher.load({
    url: urlFullProduct(categoryName, productSlug),
    refetch: refetch,
  }) as Promise<FullProduct>;
};

init();
