import { addSuffixToFilename } from "../lib/add-suffix-to-filename";
import "./ProductImage.scss";

export function ProductImage({
  category,
  slug,
  imageName,
  size = "normal",
  classes = "",
}: {
  category: string | undefined;
  slug?: string | undefined;
  imageName?: string;
  size?: "thumb" | "normal";
  classes?: string;
}) {
  let filename = imageName || slug + ".jpg";

  if (size === "thumb") filename = addSuffixToFilename(filename, "_thumb");

  const src = `/data/images/${category}/${filename}`;

  return (
    <div class={"productImage_wrapper " + classes}>
      <img
        src={src}
        alt={filename}
        loading="lazy"
        class={"productImage productImage__" + size}
      />
    </div>
  );
}
